import { useCallback, useEffect, useState } from 'react';
import { useSwipeNavigation } from './useSwipeNavigation';
import './TellMePage.css';
import FeedbackForm from './FeedbackForm';
import { useSwipeContext } from './hooks/useSwipeContext';
import { useQuery } from './hooks/useQuery';
import { useNavigate } from 'react-router-dom';

export const TellMePage = () => {
  const navigate = useNavigate();
  const from = useQuery().get('from');
  const [animation, setAnimation] = useState(
    from == 'home' ? 'slide-from-right' : 'slide-from-below');

  const swipeHandlers = useSwipeNavigation({
    swipeRightRoute: "/?from=tell-me",
    swipeLeftRoute: null,
    swipeUpRoute: null,
    swipeDownRoute: null,
    onSwipeRight: () => setAnimation('slide-right')
  });
  console.log("tellme")
  const {setSwipeHandlers} = useSwipeContext();
  useEffect(() => setSwipeHandlers(swipeHandlers), []);

  const handleBackClick = useCallback(() => {
    setAnimation('slide-right');
    setTimeout(() => navigate('/?from=tell-me'), 100);
  } ,[setAnimation, navigate]);

  return (
    <div className="page-container">
      <div className={`${animation} fixed inset-x-0 top-[30%] mx-auto w-full max-w-xl`}>
        <FeedbackForm/>
        <div className="flex justify-between text-center rounded-lg py-1 px-3 mt-8">
          <a href="https://wa.me/+529842357300" target="_blank" rel="noopener noreferrer"
             className="flex flex-col ml-6 pb-2 mb-4 md:mb-0 mx-2 justify-center max-w-[40px] md:max-w-[45px] max-h-[45px] md:max-h-[50px] transition duration-300 ease-in-out hover:scale-[120%] hover:md:scale-[130%]">
              <img src="whatsapp.svg" className="white-svg w-full h-full" />
          </a>

          <a href="https://t.me/Door_ga" target="_blank" rel="noopener noreferrer"
             className="flex flex-col pb-2 mb-4 md:mb-0 mx-2 justify-center max-w-[40px] md:max-w-[45px] max-h-[45px] md:max-h-[50px] transition duration-300 ease-in-out hover:scale-[120%] hover:md:scale-[130%]">
              <img src="telegram.svg" className="white-svg w-full h-full" />
          </a>

          <a href="https://instagram.com/doorga_ma" target="_blank" rel="noopener noreferrer"
             className="flex flex-col pb-2 mb-4 md:mb-0 mx-2 justify-center max-w-[40px] md:max-w-[45px] max-h-[45px] md:max-h-[50px] transition duration-300 ease-in-out hover:scale-[120%] hover:md:scale-[130%]">
              <img src="instagram.svg" className="white-svg w-full h-full"  />
          </a>

          <a href="https://facebook.com/doorga_ma" target="_blank" rel="noopener noreferrer"
             className="flex flex-col mr-5 pb-2 mb-4 md:mb-0 justify-center max-w-[40px] md:max-w-[50px] max-h-[45px] md:max-h-[50px] transition duration-300 ease-in-out hover:scale-[120%] hover:md:scale-[130%]">
              <img src="facebook.svg" className="white-svg white-svg w-full h-full" />
          </a>
        </div>
      </div>
      <div className={`${animation} fixed inset-x-0 bottom-[16%] md:bottom-[14.2%] mx-auto w-full max-w-6xl pr-14 flex justify-center`}>
        <div className="flex space-x-8 items-center justify-center">
          <div className="flex flex-row items-center cursor-pointer" onClick={handleBackClick}>
            <div className="arrow left pl-3 pt-2 mr-1">
              <span></span>
              <span></span>
            </div>
            <span className="ml-10 text-white whitespace-nowrap text-sm md:text-lg hover:text-opacity-80 transition duration-300 ease-in-out">
              Back
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};