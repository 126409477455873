import { useCallback, useEffect, useState } from 'react';
import { useSwipeNavigation } from './useSwipeNavigation';
import { useSwipeContext } from './hooks/useSwipeContext';
import { useQuery } from './hooks/useQuery';
import { useNavigate } from 'react-router-dom';

export const PricePage = () => {
  const navigate = useNavigate();
  const from = useQuery().get('from');
  const [animation, setAnimation] = useState(from == 'home' ? 'slide-from-left' : 'slide-from-below');

  const swipeHandlers = useSwipeNavigation({
    swipeLeftRoute: "/?from=price",
    swipeRightRoute: null,
    swipeUpRoute: null,
    swipeDownRoute: null,
    onSwipeLeft: () => setAnimation('slide-left')
  });

  const {setSwipeHandlers} = useSwipeContext();
  useEffect(() => setSwipeHandlers(swipeHandlers), []);

  const handleBackClick = useCallback(() => {
    setAnimation('slide-left');
    setTimeout(() => navigate('/?from=price'), 100);
  } ,[setAnimation, navigate]);

  return (
    <div className="page-container">
      <div className={`${animation} fixed inset-x-0 top-[28%] mx-auto w-full max-w-6xl`}>
        <div className="flex justify-center flex-col text-white nanum-myeongjo text-center rounded-lg">
          <div className="flex justify-center items-center h-fit-content">
            <div className="p-8 rounded-lg  w-full md:max-w-[60%]">
              <div className="border-b-2 text-sm md:text-xl border-custom pb-[5%] mb-4 font-nanum-myeongjo">
                <p>Choose your sensual way to expand your love</p>
              </div>
              <div className="flex justify-around flex-col items border-b-2 border-custom pb-2 mb-4">
                <div className="py-[5%] px-[3%] cursor-pointer hover:bg-yellow-100 hover:bg-opacity-10 rounded-lg group">
                  {/* <div className="text-3xl md:text-5xl x-2 mb-2 md:mb-4"><i className="fa-solid icon-group text-white group-hover:text-yellow-200"></i></div> */}
                  <span className="block text-sm md:text-xl font-small font-nanum-myeongjo">YONI HEALING - 200 USD</span>
                </div>
                <div className="py-[5%] px-[3%] cursor-pointer hover:bg-yellow-100 hover:bg-opacity-10 rounded-lg group">
                  {/* <div className="text-3xl md:text-5xl mb-2 md:mb-4"><i className="fa-solid icon-group text-white group-hover:text-yellow-500"></i></div> */}
                  <span className="block text-sm md:text-xl font-small font-nanum-myeongjo">GUIDED YONI HEALING - 300 USD</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${animation} fixed inset-x-0 bottom-[11.5%] mx-auto w-full max-w-6xl px-4 flex justify-center`}>
        <div className="flex space-x-8 items-center justify-between">
          <div className="flex flex-row items-center cursor-pointer flex-1" onClick={handleBackClick}>
            <span className="text-white whitespace-nowrap text-sm md:text-lg hover:text-opacity-80 transition duration-300 ease-in-out">
              Back
            </span>
            <div className="arrow right pl-2 pt-[80px]">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};