import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeNavigation } from "./useSwipeNavigation";
import './HomePage.css';
import { useSwipeContext } from "./hooks/useSwipeContext";
import { useQuery } from "./hooks/useQuery";

export const HomePage = () => {
  const navigate = useNavigate();
  const from = useQuery().get('from');
  const [animation, setAnimation] = useState(
    from == 'healing' ? 'slide-from-above' :
    from == 'tell-me' ? 'slide-from-left' :
    from == 'price' ? 'slide-from-right' : 'slide-from-below');

  const swipeHandlers = useSwipeNavigation({
    swipeLeftRoute: "/tell-me?from=home",
    swipeRightRoute: "/price?from=home",
    swipeUpRoute: "/healing?from=home",
    swipeDownRoute: null,
    onSwipeLeft: () => setAnimation('slide-left'),
    onSwipeRight: () => setAnimation('slide-right'),
    onSwipeUp: () => setAnimation('slide-above'),
  });

  const {setSwipeHandlers} = useSwipeContext();
  useEffect(() => setSwipeHandlers(swipeHandlers))

  const handleLeftArrowClick = useCallback(() => {
    setAnimation('slide-right');
    setTimeout(() => navigate('/price?from=home'), 100);
  } ,[setAnimation, navigate]);

  const handleDownArrowClick = useCallback(() => {
    setAnimation('slide-above');
    setTimeout(() => navigate('/healing?from=home'), 100);
  } ,[setAnimation, navigate]);

  const handleRightArrowClick = useCallback(() => {
    setAnimation('slide-left');
    setTimeout(() => navigate('/tell-me?from=home'), 100);
  } ,[setAnimation, navigate]);


  return (
    <div className={`page-container`}>
      <div className={`${animation} fixed inset-x-0 top-[32%] md:top-[32%] mx-auto w-full max-w-6xl px-4`}>
        <div className="flex justify-center">
            <img src="logo-white.png" className="lg:block md:max-w-[500px] md:max-h-[500px] object-cover" />
        </div>
      </div>

      <div className={`${animation} fixed inset-x-0 bottom-10 md:bottom-20 mx-auto w-full max-w-6xl px-4 flex justify-center transition duration-300 ease-in-out`}>
        <div className="flex space-x-8 items-center justify-between">
          <div className="flex items-center">
            <div className="arrow p-6 left cursor-pointer mb-[2.3rem]" onClick={handleLeftArrowClick}>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="flex flex-col items-center cursor-pointer flex-1" onClick={handleDownArrowClick}>
            <span
              className="text-white whitespace-nowrap text-sm md:text-lg hover:text-opacity-80 transition duration-300 ease-in-out"
            >
              Swipe to learn more
            </span>
            <div className="arrow down pt-[50px]">
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="arrow p-6 right cursor-pointer mb-[3.3rem]" onClick={handleRightArrowClick}>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};