import React, { useCallback, useState } from 'react';

function FeedbackForm() {
  const [feedback, setFeedback] = useState('');
  const [textAreaFocus, setTextAreaFocus] = useState(false);
  const [isSubmitDisabled, setSubmitDisability] = useState(false);

  const handleSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    setSubmitDisability(true);
    try {
      await fetch('/submit-review', { // Путь к вашему API
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback }),
      });
      setFeedback('');
    } catch (error) {
    }
    setSubmitDisability(false);
  }, []);

  const handleEnter = useCallback(async (e: any) => {
  },[]);

  return (
      <form onSubmit={handleSubmit} className="flex flex-col justify-center align-center">
        <textarea
          className={`flex text-center border-0 rounded-lg p-6 bg-black ${textAreaFocus ? 'bg-opacity-65' : 'bg-opacity-40'} w-full min-w-[20vw] min-h-[30vh] text-xl md:text-medium text-white font-nanum-myeongjo focus:outline-none overflow-hidden`}
          placeholder='Tell it to me or leave me a review'
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          onFocus={() => setTextAreaFocus(true)}
          onBlur={() => setTextAreaFocus(false)}
        ></textarea>
        {!!feedback && <button type="submit" disabled={isSubmitDisabled} onKeyUp={handleEnter} className="flex mx-auto mt-2 max-w-[300px] py-2 px-20  bg-white bg-opacity-35 hover:bg-opacity-50 rounded-lg text-white font-nanum-myeongjo text-sm">{isSubmitDisabled ? "working": "Send Feedback"}</button>}
      </form>
  );
}

export default FeedbackForm;
