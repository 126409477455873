import { RefCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SwipeableHandlers, useSwipeable } from "react-swipeable";

type useSwipeDurationParams = {
  swipeLeftRoute?: string | null,
  swipeRightRoute?: string | null,
  swipeUpRoute?: string | null,
  swipeDownRoute?: string | null,
  onSwipeLeft?: () => void | null,
  onSwipeRight?: () => void | null,
  onSwipeUp?: () => void | null
  onSwipeDown?: () => void | null,
  swipeDuration?: number | undefined
};

export const useSwipeNavigation = ({
  swipeLeftRoute,
  swipeRightRoute,
  swipeUpRoute,
  swipeDownRoute,
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
  swipeDuration
}: useSwipeDurationParams) => {
  const navigate = useNavigate();

  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (!!swipeLeftRoute) {
        onSwipeLeft && onSwipeLeft();
        setTimeout(() => navigate(swipeLeftRoute), 100);
      }
    },
    onSwipedRight: (e) => {
      if (!!swipeRightRoute) {
        onSwipeRight && onSwipeRight();
        setTimeout(() => navigate(swipeRightRoute), 100);
      }
    },
    onSwipedUp: (e) => {
      if (!!swipeUpRoute) {
        onSwipeUp && onSwipeUp();
        setTimeout(() => navigate(swipeUpRoute), 100);
      }
    },
    onSwipedDown: (e) => {
      if (!!swipeDownRoute) {
        onSwipeDown && onSwipeDown();
        setTimeout(() => navigate(swipeDownRoute), 100);
      }
    },
    preventScrollOnSwipe: true,
    trackTouch: true,
    trackMouse: false,
    delta: 100,
    swipeDuration
  });

  useEffect(() => {
    handlers.ref(document as any);
    // Clean up swipeable event listeners
    return () => handlers.ref({} as any);
  });

  return handlers;
};