import { Dispatch, SetStateAction, createContext, useContext } from "react";

type SwipeContextValue = {
  swipeHandlers: any,
  setSwipeHandlers: Dispatch<SetStateAction<{}>>
};

const SwipeContext = createContext<SwipeContextValue>({} as any as SwipeContextValue);

const useSwipeContext = () => {
  return useContext(SwipeContext);
};

export {SwipeContext, useSwipeContext}