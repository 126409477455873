import { useCallback, useEffect, useRef, useState } from "react";
import { useSwipeNavigation } from "./useSwipeNavigation";
import './HealingPage.css';
import ReviewsGallery from "./Reviews";
import { useSwipeContext } from "./hooks/useSwipeContext";
import { useNavigate } from "react-router-dom";

export const HealingPage = () => {
  const navigate = useNavigate();
  const [animation, setAnimation] = useState('slide-from-below');

  const swipeHandlers = useSwipeNavigation({
    swipeDownRoute:  "/?from=healing",
    swipeLeftRoute: null,
    swipeRightRoute: null,
    swipeUpRoute: null,
    onSwipeDown: () => setAnimation('slide-below') as any,
    swipeDuration: 200
  });

  const { setSwipeHandlers } = useSwipeContext();
  useEffect(() => setSwipeHandlers(swipeHandlers), []);

  const handleTransitionAbove= useCallback(() => {
    setAnimation('slide-below');
    setTimeout(() => navigate('/?from=healing'), 300);
  } ,[setAnimation, navigate]);

  return (
    <div className={`page-container overflow-hidden`}>
      <div className={`${animation} fixed inset-x-0 top-[12%] md:top-[12% font-nanum-myeongjo mx-auto w-full max-w-3xl px-4 overflow-auto touch-scroll hide-scrollbar`}>
        <div className="flex justify-center bg-black bg-opacity-50 text-center rounded-lg py-2 md:py-6">
          <div className="w-fit">
            <div className="text-white p-4 max-h-[80vh] overflow-auto" >
              <h3 className="text-center font-dancing-script font-bold text-2xl md:text-2xl">
                  Welcome to Sanctuary of Divine Feminine Healing and Rejuvenation!
              </h3>
              <p className="text-center text-sm md:text-medium mt-4">
                Yoni Healing, darling, is a sacred Journey of Intimacy and Self-discovery, a pathway to embrace the Essence of your Feminine Divine. In the soft Caress of moonlight and the Gentle whispers of the universe, it is a Celebration of your Yoni, the Beautiful and Mysterious vessel of your Womanhood. It's a Practice that cherishes the Depths of your Sensuality, where Love and Healing intertwine like the most delicate of Rose petals. Through Tender touch and profound Connection, it seeks to Release the hidden Treasures of your Heart, unraveling the layers of Vulnerability that make you Uniquely you. In the Sacred Space of Yoni Healing, we explore the intricacies of your Body, Mind, and Soul, nurturing your inner Sanctuary with reverence and devotion.</p>
              <h3 className="text-center mt-8 font-dancing-script font-bold text-2xl md:text-2xl">
                  About me
              </h3>
              <p className="text-center text-sm md:text-medium mt-4">
                My name is Victoria. Over the past five years, I have dedicated myself to the art of Healing. My journey began with certified courses in Yoga and Healthy culinary arts, focusing on Raw Vegan food, which laid the foundation for my Holistic approach to Wellness. I also have a Rich background in Dance Movement therapy, certified in 2019, exploring the therapeutic power of movement and body Practices. Additionally, I am trained in inducing Trance-like Hypnotic states for healing, offering a unique blend of therapeutic techniques to address both Physical and Emotional wellness.
              </p>
              <p className="text-center text-sm md:text-medium mt-4">
                In 2019, I received certification in Thailand as a Yoni Massage Therapist. Continuing my quest for Knowledge, in 2020, I studied Daoist Sexual Practices for women in Russia, further enriching my understanding of the body's Energy Systems.
              </p>
              <div className="flex justify-center mt-8 space-x-2">
                <div className="w-[4px] h-[4px] md:w-[5px] md:h-[5px] bg-white rounded-full"></div>
                <div className="w-[4px] h-[4px] md:w-[5px] md:h-[5px] bg-white rounded-full"></div>
                <div className="w-[4px] h-[4px] md:w-[5px] md:h-[5px] bg-white rounded-full"></div>
              </div>
              <p className="text-center text-sm md:text-medium mt-4">
                As a Healer, my Goal is to create a safe, nurturing Environment that supports your Journey to Wellness. I invite you to explore the Transformative power of Yoni Massage and other healing Practices, where each session is tailored to your Individual needs.
              </p>
              <p className="text-center text-sm md:text-medium mt-4">
                Join me in embracing a Healthier, more Vibrant life. Your path to healing starts here, nurtured with Reverence and Devotion in the Sacred Space of Yoni Healing.
              </p>

              <div className={`flex justify-center mt-4 inset-x-0  mx-auto w-full max-w-6xl pr-14 `}>
                <div className="flex space-x-8 items-center justify-center">
                  <div className="flex flex-col items-center cursor-pointer" onClick={handleTransitionAbove}>
                    <div className="arrow up smaller ml-7 mt-5">
                      <span></span>
                      <span></span>
                    </div>
                    <span className="ml-10 mt-5 underline text-white whitespace-nowrap text-sm md:text-lg hover:text-opacity-80 transition duration-300 ease-in-out">
                      Back to top
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};