import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PricePage } from './PricePage';
import { HealingPage } from './HealingPage';
import { TellMePage } from './TellMePage';
import { HomePage } from './HomePage';
import { NavBar } from './NavBar';
import './App.css';
import { useState } from 'react';
import { SwipeContext } from './hooks/useSwipeContext';

const App = () => {
  return (
    <Router>
      <NavigationWrapper />
    </Router>
  );
};

const NavigationWrapper = () => {
  const [swipeHandlers, setSwipeHandlers] = useState<any>({});
  console.log(swipeHandlers.ref);
  return (
    <SwipeContext.Provider value={{ swipeHandlers, setSwipeHandlers }}>
       <div {...swipeHandlers} className='relative'>
        <img src="background-cenote.story.png" alt="Story Image" className="block fixed lg:hidden scale-[125%] translate-y-[-5%] w-full h-screen object-cover z-index-[-100]" />
        <img src="background-cenote.web.png" alt="Web Image" className="hidden lg:block fixed scale-[110%] translate-x-[2%] translate-y-10 w-full h-screen object-cover z-index-[-100]" />
        {/* <div className="absolute inset-0 bg-gray-500 bg-opacity-50 z-index-[-99]"></div> */}
        <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/healing" element={<HealingPage />} />
            <Route path="/price" element={<PricePage />} />
            <Route path="/tell-me" element={<TellMePage />} />
          </Routes>
      </div>
    </SwipeContext.Provider>
  );
};

export default App;