import { NavLink, useLocation } from "react-router-dom";

export const NavBar = () => {
  const location = useLocation();
  const linkClass = (isActive: boolean) => `text-2xl sm:text-3xl md:text-4xl bg-transparent rounded-full transition duration-300 ease-in-out ${isActive ? 'scale-[150%] translate-y-3 md:scale-[125%] md:translate-y-5' : ''}`;
  const isHealingActive = ["/healing"].some(path => location.pathname === path);

  return (
    <nav className="fixed top-0 w-full px-6 py-2 text-white z-100 font-black-dragon">
      <div className="max-w-3xl w-full mx-auto flex justify-between">
        <NavLink to="/price" className={({ isActive }) => linkClass(isActive)}>Price</NavLink>
        <NavLink to="/" className={() => linkClass(isHealingActive)}>Healing</NavLink>
        <NavLink to="/tell-me" className={({ isActive }) => linkClass(isActive)}>Tell Me</NavLink>
      </div>
    </nav>
  );
};
